.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper * {
  font-family: "Poppins" !important;
}
#alert-dialog-description {
  color: black !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.form-div {
  margin: 5px 0;
}

.sidebar .logo-details .sidebar_logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .sidebar_logo {
  opacity: 1;
}

.logo {
  width: 50px;
  height: 50px;
}
.bg {
  background-color: #e4e9f7;
}
h1 {
  text-transform: uppercase;
}
.divider {
  border-bottom: 1px solid rgb(180, 180, 180);
  margin: 10px 0;
}

.top {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  gap: 10px;
  padding: 5px 0;
}

.content {
  padding: 10px 0px;
}
.flex-body {
  /* display: flex; */
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e4e9f7;
}

.center-form {
  background-color: white;
  /* border-radius: 3px; */
  box-shadow: 0px 2px 2px grey;
  padding: 20px;
  width: 400px;
}

.shadow {
  box-shadow: 0px 2px 2px grey;
}
.form-div {
  margin: 10px 0;
}
.form-div label {
  display: block;
}

.form-div input,
.form-div textarea,
.form-div select {
  width: 100%;
}
textarea {
  resize: vertical;
}

.bolder {
  font-weight: bolder;
}

.primary_color {
  color: var(--primary-color);
}
.light_blue {
  color: #23b1e9;
}

.smaller_ft {
  font-size: 0.8rem;
}
.small_ft {
  font-size: 0.9rem;
}

.version_ft {
  font-size: 0.7rem;
  font-weight: normal;
}

.grey {
  color: rgb(95, 95, 95);
}
.display_img {
  max-width: 200px;
}
.input {
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid rgb(184, 183, 183);
}

.form-div input:focus {
  outline: 1px solid var(--primary-color);
  border: 1px solid var(--primary-color);
}

.submit,
.button {
  padding: 7px 15px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: 0.2s;
  border: none;
}

.submit:hover,
.button:hover {
  box-shadow: 2px 2px 2px grey;
}

.file_button {
  background-color: rgb(48, 7, 82);
  border: none;
}

.img_preview {
  padding: 5px 0px;
}

canvas {
  margin: 5px 0;
  display: block;
}

.none {
  display: none !important;
}

.center {
  text-align: center;
}

fieldset {
  padding: 10px;
  /* background-color: white; */
}

form .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

form .grid2 {
  grid-template-columns: 1fr 1fr;
}
form .grid3 {
  grid-template-columns: 1fr 1fr 1fr;
}
form .grid4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
svg {
  cursor: pointer;
}
.action_btn {
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 0.7rem;
}

.white {
  color: white;
}

.flex_space {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.loading {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}
.mini_loader {
  display: flex;
  align-items: center; /* This centers vertically */
  justify-content: center; /* This centers horizontally */
  height: 80vh;
}
.form_loader {
  display: flex;
  justify-content: center; /* This centers horizontally */
}
.mini_loading {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}
.form_loading {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.mini {
  font-size: 0.7rem;
}

.del {
  background-color: rgb(218, 48, 5);
  border: none;
}
tr.user_item {
  text-align: start;
  font-size: 0.8rem;
  font-weight: normal;
}

table {
  border-spacing: 0;
}

.user_list_table {
  width: 100%;
}
.auto_width{
  width: auto !important;
}

th,
td {
  text-align: start;
  padding: 5px 20px;
  /* max-width: 250px; */
  border: none;
  margin: 0;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.user_image {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.tabs {
  background-color: white;
}

.tabs button.tab {
  /* Can conflict with sidebar .tab */
  padding: 7px 10px;
  cursor: pointer;
  border: none;
  border-right: 1px solid #d1d0d0;
  outline: none;
  transition: 0.3s;
}
.tabs button.tab:hover {
  background-color: #ddd;
}
.tabs button.tab.active {
  background-color: #b4b4b4;
  color: white;
}

.report_page {
  /* display: flex;
    justify-content: center; */
  min-width: 300px;
  /* max-width: 600px; */
  background-color: white;
  margin: auto;
  padding: 10px;
}
.page_top {
  display: grid;
  grid-template-columns: auto auto auto;
}
.page_body {
  font-size: 0.9rem;
}
.page_info {
  display: grid;
  grid-template-columns: auto auto auto;
}
.page_info img {
  border-radius: 5px;
}

.page_subjects {
  display: flex;
  justify-content: center;
}

.page_footer td {
  padding: 0;
}

.YES, .success {
  color: green;
}

.NO, .error {
  color: red;
}

.file_picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed black;
  height: 200px;
  cursor: pointer;
}

.exl_btn {
  background-color: #27ae60;
}
.set_atn_btn {
  background-color: #2980b9;
}

.view_atn_btn {
  background-color: #8e44ad;
}
