.user_page{
    display: flex;
    justify-content: center;
}

.user_board{
    background-color: white;
    padding: 10px;
}

.user_info_section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;
}

.user_info_image{
    border-radius: 5px;
    width: 200px;
    height: 200px;
}

.user_info_name{
    font-size: larger;
}

.user_info_age{
    font-size: .8rem;
}

.info_area > div{
    margin: 5px 0;
}

.sub_area{
    font-size: .9rem;
}

.user_id_image{
    border-radius: 5px;
    max-height: 200px;
}