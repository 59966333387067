#dashboard{
    background-color: white;
}

#dashboard > div{
    height: calc(100% / 3);
    width: calc(100% / 3);
    /* background-color: aqua; */
    display: inline-block;
    padding: 10px;
}

#dashboard .card{
    background-color: blueviolet;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.card > div{
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    justify-content: center;
    /* align-items: center; */
    color: white;
}
.card .count{
    font-size: 1.5rem;
    font-weight: bold;
}

.card .label{
    font-size: 1.1rem;
}

.card .icons{
    font-size: 2em;
}

.somecolor{ 
    color: #87d1e4;
}